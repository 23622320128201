export function apiRequest(controller, action, data) {
    return new Promise(function(resolve, reject){
        $.ajax({
            type: 'POST',
            url: '/ajax-api',
            data: {
                controller: controller,
                action: action,
                data: JSON.stringify(data)
            },
            success: function(response){
                if(response.success){
                    resolve(response);
                } else {
                    reject(response);
                }
            },
            error: function(error){
                reject(error);
            }
        });
    });
}