import anime from 'animejs/lib/anime.es.js';

//Javascript
import '../navbar.js';

//Stylesheets
import '../../scss/pages/homepage.scss';
import '../../scss/partials/navbar.scss';


window.onload = function(){
    //Navbar logic
    document.addEventListener('scroll', function(e) {
        navbarLogicRun();

    });
}


function navbarLogicRun(){
    //Get elements and constants
    let navbar = document.querySelector('.navbar');
    const scrollLocation = window.scrollY;
    const navbarHeight = navbar.offsetHeight;


    //Has the user scrolled past the navbar's height on the page
    if(scrollLocation >= navbarHeight){
        //Only run animations/change classes if required (i.e. is currently hidden)
        if(navbar.classList.contains("navbar-status-hidden")){
            //Show navbar
            navbar.classList.remove("navbar-status-hidden");
            navbar.classList.add("navbar-status-fixed");

            //Set animation start height
            navbar.style.top = "-" + navbarHeight + "px"

            //Animate slide down
            anime({
                targets: navbar,
                easing: 'linear',
                duration: 250,
                top: '0'
            });
        }
    } else {
        //Only run animations/change classes if required (i.e. is currently showing)
        if(navbar.classList.contains("navbar-status-fixed")){
            //Animate slide down
            anime({
                targets: navbar,
                easing: 'linear',
                duration: 250,
                top: '-' + navbarHeight + 'px',
                complete: function(anim) {
                    //Hide navbar
                    navbar.classList.add("navbar-status-hidden");
                    navbar.classList.remove("navbar-status-fixed");
                }
            });
        }
    }
}


//FAQ Accordion Logic
document.querySelectorAll('.accordion-item').forEach(function(element) {

    //Add click event
    element.querySelector('.accordion-question').addEventListener('click', function() {
        //Don't continue if accordion is already open
        if(element.classList.contains('open')) return;

        //Get elements
        let answerBlockWrapper = element.querySelector('.accordion-answer-animation-wrapper');
        let answerBlock = element.querySelector('.accordion-answer');
        let questionIcon = element.querySelector('.accordion-question .icon');


        //Set answer block to correct width before egtting height
        answerBlock.style.width = element.querySelector('.accordion-question').offsetWidth + "px";

        //Get target height
        const answerHeight = answerBlock.offsetHeight;

        //Reset answer block
        answerBlock.style.width = "100%";


        //Set starting height
        answerBlockWrapper.style.height = 0;

        //Close all currently open accordions
        document.querySelectorAll('.accordion-item.open').forEach(function(element) {
            //Rotate arrow icon back to starting point
            anime({
                targets: element.querySelector('.accordion-question .icon'),
                easing: 'linear',
                duration: 250,
                rotate: '0deg'
            });
            
            //Slide up any open accordions
            anime({
                targets: element.querySelector('.accordion-answer-animation-wrapper'),
                easing: 'linear',
                duration: 250,
                height: 0,
                complete: function(anim) {
                    element.classList.remove("open");
                }
            });
        });

        //Add open tag to accordion
        element.closest('.accordion-item').classList.add('open');

        //Rotate arrow icon
        anime({
            targets: questionIcon,
            easing: 'linear',
            duration: 250,
            rotate: '90deg'
        });

        //Slide down accordion
        anime({
            targets: answerBlockWrapper,
            easing: 'linear',
            duration: 250,
            height: answerHeight,
        });
    });
});
