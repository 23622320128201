export function validateForm(joiSchema, fields){
    const { error } = joiSchema.validate(fields,{
        abortEarly: false,
    });

    let fieldsWithErrors = [];

    // Handle errors
    if(typeof error !== "undefined") {
        error.details.forEach(err => {
            // if already in list, skip
            if(fieldsWithErrors.indexOf(err.context.key) !== -1) return;

            // Get field name
            const fieldName = err.context.key;

            // Add to fields with errors
            fieldsWithErrors[fieldName] = err.message;
        });

        return fieldsWithErrors;
    } else {
        return {};
    }
}
window.validateForm = validateForm;

