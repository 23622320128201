const Joi = require('joi');
let cJoi = Joi.extend(require('joi-phone-number'));
const {validateForm, addErrorToInput} = require("./form-validation");
const {getSurveyReports} = require("./api/survey");
const {saveBillingAddress} = require("./api/account");
const body = $('body');

//Document ready
$(document).ready(function() {
    //Validate login form (if loaded in)
    checkAccountLoginForm();

    //Start the resend timer refresh
    startAccountEmailVerificationResendTimer();

    //Start checker timer
    startAccountEmailVerificationCheckTimer();

    //Start checker timer
    startAccountPaymentConfirmationCheckTimer();

    //Validate password reset form (if loaded in)
    checkAccountPasswordReset();
});

//#region Login form

//Check login form when inputs change
body.on("input", "#account-login-form .input input[name=loginEmail], #account-login-form .input input[name=loginPassword]", function(e) {
    //Check form (e.g. disable submit button if a field is empty)
    checkAccountLoginForm();
});

// On dropdown select, drop any errors
body.on("click", ".wtp-dropdown .dropdown-content ul li", function(e) {
    let dropdown = $(this).closest(".wtp-dropdown");

    // Find input container
    let inputContainer = dropdown.parent(".wtp-field");

    // Remove error class
    inputContainer.removeClass("error");

    // Remove error message
    inputContainer.find(".field-error").remove();
});

//Submit login form
body.on("submit", "#account-login-form form", function(e) {
    e.preventDefault();

    //Get form data
    let formData = $(this).serializeArray();

    //Validation schema
    const schema = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }).max(254).required().messages({
            'string.email': 'Please enter a valid email address',
        }),
        password: Joi.string().max(32).required()
    });

    //Validate inputs
    const { error } = schema.validate({
        email: $("#account-login-form .input input[name=loginEmail]").val(),
        password: $("#account-login-form .input input[name=loginPassword]").val()
    });

    //Hide any existing error messages
    $("#account-login-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined"){
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch(fieldName){
            case "password":
                errorMessageElement = $("#account-login-form .input input[name=loginPassword]").siblings(".error");
                break;
            case "email":
            default:
                errorMessageElement = $("#account-login-form .input input[name=loginEmail]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {
        //Clear an existing error messages
        $("#account-login-form .error-block").addClass("hide");
        $("#account-login-form .error-block .title").text("");
        $("#account-login-form .error-block .description").text("");

        //Set button state
        let submitButton = $(this).find("button[type=submit]");
        submitButton.addClass("loading");

        //Set input states
        $("#account-login-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-login-form .input input").removeAttr('disabled');

                if(data.success){
                    document.location.href = data.redirect;
                } else {
                    $("#account-login-form .error-block .title").text(data.title);
                    $("#account-login-form .error-block .description").append(data.message);
                    $("#account-login-form .error-block").removeClass("hide");
                }
            }
        });
    }
});

//#endregion

//#region Registration form

//Check the form every time a change is made to the inputs
body.on("input", "#account-register-form .input input", function(e) {
    // Check if has error message
    if($(this).siblings(".error").length > 0){
        // Hide error message
        $(this).siblings(".error").addClass("hide");
    }
});

//AJAX form submit
body.on("submit", "#account-register-form form", function(e) {
    e.preventDefault();

    //Get form data
    let formData = $(this).serializeArray();


    //Validation schema
    const schema = cJoi.object({
        firstName: cJoi.string().min(2).max(50).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a first name',
            'any.required': 'Please enter a first name'
        }),
        lastName: cJoi.string().min(2).max(50).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a last name',
            'any.required': 'Please enter a last name'
        }),
        company: cJoi.string().min(0).max(100).messages({
            'string.max': 'Please enter at most {#limit} characters',
        }),
        email: cJoi.string().email({ tlds: { allow: false } }).max(254).required().messages({
            'string.email': 'Please enter a valid email address.',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter an email address',
        }),
        phoneNumber: cJoi.string().phoneNumber().min(1).max(20).required().messages({
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a phone number',
            'any.required': 'Please enter a phone number',
            'phoneNumber.invalid': 'Please enter a valid phone number'
        }),
        password: cJoi.string().min(6).max(32).required().messages({
            'string.min': 'Password must be at least 6 characters long.',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a password',
        }),
        confirmPassword: cJoi.string().max(32).required().valid(cJoi.ref('password')).messages({
            'any.only': 'Passwords do not match.',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please confirm your password',
        })
    });

    //Validate inputs
    const { error } = schema.validate({
        firstName: $("#account-register-form .input input[name=registerFirstName]").val(),
        lastName: $("#account-register-form .input input[name=registerLastName]").val(),
        company: $("#account-register-form .input input[name=registerCompany]").val(),
        email: $("#account-register-form .input input[name=registerEmail]").val(),
        phoneNumber: $("#account-register-form .input input[name=registerPhoneNumber]").val(),
        password: $("#account-register-form .input input[name=registerPassword]").val(),
        confirmPassword: $("#account-register-form .input input[name=registerConfirmPassword]").val()
    });

    //Hide any existing error messages
    $("#account-register-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined") {
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch (fieldName) {
            case "firstName":
                errorMessageElement = $("#account-register-form .input input[name=registerFirstName]").siblings(".error");
                break;
            case "lastName":
                errorMessageElement = $("#account-register-form .input input[name=registerLastName]").siblings(".error");
                break;
            case "company":
                errorMessageElement = $("#account-register-form .input input[name=registerCompany]").siblings(".error");
                break;
            case "phoneNumber":
                errorMessageElement = $("#account-register-form .input input[name=registerPhoneNumber]").siblings(".error");
                break;
            case "confirmPassword":
                errorMessageElement = $("#account-register-form .input input[name=registerConfirmPassword]").siblings(".error");
                break;
            case "password":
                errorMessageElement = $("#account-register-form .input input[name=registerPassword]").siblings(".error");
                break;
            case "email":
            default:
                errorMessageElement = $("#account-register-form .input input[name=registerEmail]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {
        //Set button state
        let submitButton = $(this).find("button[type=submit]");
        submitButton.addClass("loading");

        //Set input states
        $("#account-register-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-register-form .input input").removeAttr('disabled');

                $("#account-register-form .error-block").addClass("hide");
                $("#account-register-form .error-block .title").text("");
                $("#account-register-form .error-block .description").text("");

                if(data.success){
                    if(data.reason === "account_made_go_to_verification"){
                        window.location.replace(data.url);
                    }
                } else {
                    $("#account-register-form .error-block .description").append(data.message);
                    $("#account-register-form .error-block").removeClass("hide");
                }

            }
        });
    }
});

//#endregion


body.on("click", "#account-manage-payments", function (e){
    let button = $(this);

    // Disable button
    button.prop("disabled", true);

    // Retrieve link
    $.ajax({
        type: "POST",
        url: window.location.href,
        data: {
            action: "getStripeCustomerPortalSession"
        },
        success: function (data) {
            // Enable button
            button.prop("disabled", false);

            if(data.success){
                // Open in new tab
                window.open(data.url, '_blank').focus();
            } else {
                alert("There was an issue loading the payment portal. Please try again.");
            }
        }
    });
});

body.on("click", "#account-disable-contract-auto-renewal", function (e){
    let button = $(this);
    let buttonText = button.find(".text")

    // Disable button
    button.prop("disabled", true);

    // Retrieve link
    $.ajax({
        type: "POST",
        url: window.location.href,
        data: {
            action: "toggleContractAutoRenew"
        },
        success: function (data) {
            // Enable button
            button.prop("disabled", false);

            if(data.success){
                if(data.contractAutoRenew){
                    buttonText.text("Disable auto-renewal");
                    $(".contract-auto-renew-enabled-text").show();
                    $(".contract-auto-renew-disabled-text").hide();
                } else {
                    buttonText.text("Enable auto-renewal");
                    $(".contract-auto-renew-enabled-text").hide();
                    $(".contract-auto-renew-disabled-text").show();
                }
            } else {
                alert("There was an issue toggling auto-renewal. Please try again.");
            }
        }
    });
});

//Password reset page
$(document).ready(function() {
    //Enable jQuery validate on login form
    // $("#account-password-reset-form form").validate();

    //Check the form every time a change is made to the inputs
    body.on("input", "#account-password-reset-form .input input[name=registeredEmail]", function(e) {
        //Check login form
        checkAccountPasswordReset();
    });

    //AJAX form submit
    body.on("submit", "#account-password-reset-form form", function(e) {
        e.preventDefault();

        $("#account-password-reset-form .success-block").addClass("hide");

        //Get form data
        let formData = $(this).serializeArray();
        formData = formData.concat([
            {
                name: "action",
                value: "send"
            }
        ]);

        //Set button state
        let submitButton = $("#account-password-reset-form .password-reset-button");
        submitButton.addClass("loading");
        submitButton.attr("disabled", true);

        //Set input states
        $("#account-password-reset-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-password-reset-form .input input").removeAttr('disabled');
                $("#account-password-reset-form .error-block").addClass("hide");
                $("#account-password-reset-form .error-block .title").text("");
                $("#account-password-reset-form .error-block .description").text("");

                if(data.success){
                    //Reset all fields
                    $("#account-password-reset-form .input input").val("");

                    $("#account-password-reset-form .success-block").removeClass("hide");
                } else {
                    $("#account-password-reset-form .error-block .title").append(data.title);
                    $("#account-password-reset-form .error-block .description").append(data.message);
                    $("#account-password-reset-form .error-block").removeClass("hide");
                }
                console.log(data);
            }
        });
    });
});

//Password reset change page
body.on("submit", "#account-password-reset-change-form form", function(e) {
    e.preventDefault();

    //Validation schema
    const schema = Joi.object({
        password: Joi.string().min(6).max(32).required().messages({
            'string.min': 'Password must be at least 6 characters long'
        }),
        confirmPassword: Joi.string().max(32).required().valid(Joi.ref('password')).messages({
            'any.only': 'Passwords do not match'
        })
    });

    //Validate inputs
    const { error } = schema.validate({
        password: $("#account-password-reset-change-form .input input[name=newPassword]").val(),
        confirmPassword: $("#account-password-reset-change-form .input input[name=newConfirmPassword]").val()
    });

    //Hide any existing error messages
    $("#account-password-reset-change-form .input .error").addClass("hide");

    //Handle errors
    if(typeof error !== "undefined") {
        let errorMessageElement = null;
        const errorMessage = error.details[0].message;
        const fieldName = error.details[0].path[0];

        //Get correct error message element
        switch (fieldName) {
            case "password":
                errorMessageElement = $("#account-password-reset-change-form .input input[name=newPassword]").siblings(".error");
                break;
            case "confirmPassword":
            default:
                errorMessageElement = $("#account-password-reset-change-form .input input[name=newConfirmPassword]").siblings(".error");
                break;
        }

        //Change error message
        errorMessageElement.text(errorMessage);

        //Show error message
        errorMessageElement.removeClass("hide");
    } else {

        //Get elements
        let successBlock = $("#account-password-reset-change-form .success-block");
        let submitButton = $(this).find("button[type=submit]");

        //Get form data
        let formData = $(this).serializeArray();
        formData = formData.concat([
            {
                name: "action",
                value: "reset"
            },
            {
                name: "token",
                value: $("#account-password-reset-change-form #token").val()
            }
        ]);

        //Set button state
        submitButton.addClass("loading");

        //Set block states
        successBlock.addClass("hide");

        //Set input states
        $("#account-password-reset-change-form .input input").prop("disabled", true);

        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set button state
                submitButton.removeClass("loading");

                //Set input states
                $("#account-password-reset-change-form .input input").removeAttr('disabled');

                //Hide submit button
                $("#account-password-reset-change-form .password-reset-change-button").hide();

                //Hide and reset the error message
                $("#account-password-reset-change-form .error-block").addClass("hide");
                $("#account-password-reset-change-form .error-block .title").text("");
                $("#account-password-reset-change-form .error-block .description").text("");

                if(data.success){
                    //Show success message
                    successBlock.removeClass("hide");

                    //Show account link
                    $("#account-password-reset-change-form .account-link-button").removeClass("hide");

                    //Hide fields
                    $("#account-password-reset-change-form form .block").hide();
                } else {
                    $("#account-password-reset-change-form .error-block .description").append(data.message);
                    $("#account-password-reset-change-form .error-block").removeClass("hide");
                }

            }
        });
    }
});

body.on("submit", "#account-page-change-password", function(e) {
    e.preventDefault();

    let inputsAndSubmitButton = $("#account-page-change-password input, #account-page-change-password button[type=submit]");
    let formElement = $(this);

    //Get form data
    let formData = formElement.serializeArray();

    //Hide and reset the error message
    formElement.find(".field-error").remove();

    // Remove any alerts
    formElement.find(".wtp-alert").remove();

    const schema = Joi.object({
        currentPassword: Joi.string().min(6).max(32).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a current password',
            'any.required': 'Please enter a current password'
        }),
        newPassword: Joi.string().min(6).max(32).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a new password',
            'any.required': 'Please enter a new password'
        })
    });

    let fields = {};

    // Flatten and remove action field
    for (let i = 0; i < formData.length; i++) {
        if(formData[i].name !== "action"){
            fields[formData[i].name] = formData[i].value;
        }
    }

    // Validate inputs
    let errors = validateForm(schema, fields);

    // If errors
    if(Object.keys(errors).length > 0){
        // Add errors to inputs
        for (let fieldName in errors) {
            let errorMessage = errors[fieldName];
            let input = formElement.find(`input[name=${fieldName}]`);

            // Get field container
            let inputContainer = input.closest(".wtp-field");

            // Add error class to field
            inputContainer.addClass("error");

            // Create error message element
            const errorMessageElement = $(`<span class="field-error">${errorMessage}</span>`);

            // Add error message to field
            inputContainer.append(errorMessageElement);
        }
    } else {
        //Set input states
        inputsAndSubmitButton.prop("disabled", true);

        // Send to server
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set input states
                inputsAndSubmitButton.removeAttr('disabled');

                if(typeof data.success === "undefined" || !data.success){
                    let errorMessage = "This was an issue changing your password. Please try again."

                    if(typeof data.errors.currentPassword !== 'undefined' && data.errors.currentPassword.includes('Current password is incorrect.')){
                        errorMessage = "Current password is incorrect."
                    }

                    // Add error message
                    formElement.prepend(`<div class="wtp-alert error">${errorMessage}</div>`);
                } else {
                    // Add success message
                    formElement.prepend('<div class="wtp-alert success">Password successfully changed!</div>');
                }
            }
        });
    }
});

// Save personal details
body.on("submit", "#account-page-save-personal-details", function(e) {
    e.preventDefault();

    let inputsAndSubmitButton = $("#account-page-save-personal-details input, #account-page-save-personal-details button[type=submit]");
    let formElement = $(this);

    //Get form data
    let formData = formElement.serializeArray();

    //Hide and reset the error message
    formElement.find(".field-error").remove();

    // Remove any alerts
    formElement.find(".wtp-alert").remove();


    const schema = Joi.object({
        firstName: Joi.string().min(2).max(50).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a first name',
            'any.required': 'Please enter a first name'
        }),
        lastName: Joi.string().min(2).max(50).alphanum().required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a last name',
            'any.required': 'Please enter a last name'
        }),
        company: Joi.string().min(0).max(100).messages({
            'string.max': 'Please enter at most {#limit} characters',
        }),
        phoneNumber: Joi.string().min(1).max(20).required().messages({
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a phone number',
            'any.required': 'Please enter a phone number',
        }),
    });

    let fields = {};

    // Flatten and remove action field
    for (let i = 0; i < formData.length; i++) {
        if(formData[i].name !== "action"){
            fields[formData[i].name] = formData[i].value;
        }
    }

    // Validate inputs
    let errors = validateForm(schema, fields);

    // If errors
    if(Object.keys(errors).length > 0){
        // Add errors to inputs
        for (let fieldName in errors) {
            let errorMessage = errors[fieldName];
            let input = formElement.find(`input[name=${fieldName}]`);

            // Get field container
            let inputContainer = input.closest(".wtp-field");

            // Add error class to field
            inputContainer.addClass("error");

            // Create error message element
            const errorMessageElement = $(`<span class="field-error">${errorMessage}</span>`);

            // Add error message to field
            inputContainer.append(errorMessageElement);
        }
    } else {
        //Set input states
        inputsAndSubmitButton.prop("disabled", true);

        // Send to server
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: formData,
            success: function (data) {
                //Set input states
                inputsAndSubmitButton.removeAttr('disabled');

                if(typeof data.success === "undefined" || !data.success){
                    // Add error message
                    formElement.prepend('<div class="wtp-alert error">This was an issue saving your details. Please try again.</div>');
                } else {
                    // Add success message
                    formElement.prepend('<div class="wtp-alert success">Personal details successfully saved!</div>');
                }
            }
        });
    }
});

// Save billing address
body.on("submit", "#account-page-save-billing-address", function(e) {
    e.preventDefault();

    let inputsAndSubmitButton = $(this).find("input, button[type=submit]");
    let formElement = $(this);

    // Get form data
    let formData = formElement.serializeArray();

    // Hide and reset the error message
    formElement.find(".field-error").remove();

    // Remove any alerts
    formElement.find(".wtp-alert").remove();

    const schema = Joi.object({
        line1: Joi.string().min(2).max(250).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter an address',
            'any.required': 'Please enter an address'
        }),
        line2: Joi.string().min(0).max(250).messages({
            'string.max': 'Please enter at most {#limit} characters',
        }),
        city: Joi.string().min(2).max(50).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a city',
            'any.required': 'Please enter a city'
        }),
        state: Joi.string().min(2).max(50).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a state',
            'any.required': 'Please enter a state'
        }),
        postalCode: Joi.string().min(2).max(20).required().messages({
            'string.min': 'Please enter at least {#limit} characters',
            'string.max': 'Please enter at most {#limit} characters',
            'string.empty': 'Please enter a postal code',
            'any.required': 'Please enter a postal code'
        }),
    });

    let fields = {};

    // Flatten and remove action field
    for (let i = 0; i < formData.length; i++) {
        if(formData[i].name !== "action"){
            fields[formData[i].name] = formData[i].value;
        }
    }

    // Validate inputs
    let errors = validateForm(schema, fields);

    // Get country value
    let country = formElement.find("#billingAddressCountry .button .selected").data("value");

    // Validate country
    if(typeof country === "undefined" || country === "" || country.length < 2){
        errors["country"] = "Please select a country";
    }

    // If errors
    if(Object.keys(errors).length > 0){
        // Add errors to inputs
        for (let fieldName in errors) {
            let input = null;
            // Handle error for dropdown
            if(fieldName === "country"){
                input = formElement.find(`#billingAddressCountry`);
            } else {
                input = formElement.find(`input[name=${fieldName}]`);
            }

            let errorMessage = errors[fieldName];

            // Get field container
            let inputContainer = input.closest(".wtp-field");

            // Add error class to field
            inputContainer.addClass("error");

            // Create error message element
            const errorMessageElement = $(`<span class="field-error">${errorMessage}</span>`);

            // Add error message to field
            inputContainer.append(errorMessageElement);
        }
    } else {
        // Set input states
        inputsAndSubmitButton.prop("disabled", true);

        // Add disabled attribute to country dropdown
        formElement.find("#billingAddressCountry").addClass("disabled");

        // Add country to form data
        formData.push({
            name: "countryCode",
            value: country
        });

        saveBillingAddress(formData).then(function(response) {
            // Set input states
            inputsAndSubmitButton.removeAttr('disabled');

            // Enable country dropdown
            formElement.find("#billingAddressCountry").removeClass("disabled");

            // Add success message
            formElement.prepend('<div class="wtp-alert success">Billing address successfully saved!</div>');

            // Emmit success event on form
            formElement.trigger("billingAddressSaved");
        }).catch(function(error) {
            console.error(error);
            alert("There was an issue saving your billing address. Please try again.");
        });
    }
});


// Remove any errors when input changes
body.on("input", "#account-page-save-personal-details .wtp-field.error input, #account-page-change-password .wtp-field.error input, #account-page-save-billing-address .wtp-field.error input", function(e) {
    let fieldElement = $(this).closest(".wtp-field");

    // Remove error class
    fieldElement.removeClass("error");

    // Remove error message
    fieldElement.find(".field-error").remove();
});

//Resend email verification email
body.on("click", ".email-verification-page .resend-button", function(e) {
    e.preventDefault();

    //Get elements
    let resendNotice = $(".email-verification-page .resend-notice");
    let button = $(this);

    //Disable the button
    button.prop('disabled', true);

    //Clear existing notice
    resendNotice.text("");
    resendNotice.removeClass("error");
    resendNotice.removeClass("success");

    let formData = {
        action: "resend",
        publicToken: $(".email-verification-page #public-token").val()
    };

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: formData,
        success: function (data) {
            //Enable button again
            button.removeAttr("disabled");

            //Handle response
            if(data.success){
                resendNotice.text("We have resent your verification email.");
                resendNotice.addClass("success");
                $(".email-verification-page .resend-button").data("resend-timestamp", data.resend_available);

                if(data.can_resend){
                    startAccountEmailVerificationResendTimer();
                } else {
                    button.prop('disabled', true);
                }

            } else {
                switch(data.reason){
                    case "missing_token":
                        resendNotice.text("Missing public token. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "not_found":
                        resendNotice.text("Email verification not found. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "not_needing_verified":
                        resendNotice.text("Email is not waiting to be verified. Refresh page and try again.");
                        resendNotice.addClass("error");
                        break;
                    case "too_soon":
                        resendNotice.text("Attempting to resend too soon. Please wait and try again later.");
                        resendNotice.addClass("error");
                        break;
                    case "too_many_emails":
                        resendNotice.text("You have reached the maximum number of email resends. Please contact us.");
                        resendNotice.addClass("error");
                        break;
                }
            }
        }
    });
});

//Account page
body.on("click", ".account-page .nav-tabs-row .nav-tabs .nav-item button", function(e){
    //Skip if already active
    if($(this).parent().hasClass("active")) return;

    //Remove the existing active state
    $(".account-page .nav-tabs-row .nav-tabs .nav-item.active").removeClass("active");

    //Add active state to clicked button
    $(this).parent().addClass("active");

    //Get the active page name
    let page = $(this).data("page");

    //Close an existing open pages
    $(".account-page .pages-container .page.open").removeClass("open");

    //Open the clicked button
    $(".account-page .pages-container .page[data-page='" + page + "']").addClass("open");

    //Add url fragment
    window.location.hash = page;
});


// Plan Pricing Page
body.on("click", ".account-plan-pricing-table .choose-plan-button", function(e){
    let button = $(this);
    let planShortcode = button.data("plan-shortcode");

    if (planShortcode === undefined) return;

    // Disable button
    button.prop("disabled", true);

    // AJAX request
    $.ajax({
        type: "POST",
        url: window.location.href,
        data: {
            action: "getStripeCheckoutSession",
            planShortcode: planShortcode
        },
        success: function (data) {
            // Enable button
            button.prop("disabled", false);

            if(data.success){
                // Open in new tab
                window.open(data.checkout_url, '_blank').focus();
            } else {
                if (typeof data.billing_address_valid !== "undefined" && !data.billing_address_valid) {
                    // Open modal
                    $(".modal[data-modal='billingAddressForm']").addClass("open");

                    // Set plan short code
                    $(".modal[data-modal='billingAddressForm'] input[name=planShortCode]").val(planShortcode);
                } else {
                    alert("There was an issue loading the payment portal. Please try again.");
                }
            }
        }
    });
});

// Billing support alert popup
body.on("click", ".billing-support-alert", function(e) {
    alert("Please contact us and we will be happy to help you with your billing. \nEmail: contact@whatsthepayback.com");
});


$(document).ready(function() {
    //Check to see if on accounts page
    if($(".account-page").length < 1) return;

    //Handle opening tabs if in URL
    if(window.location.hash){
        let page = window.location.hash.replace('#','');

        //Close open page
        $(".account-page .pages-container .page.open").removeClass("open");

        //Deactivate open button
        $(".account-page .nav-tabs-row .nav-tabs .nav-item.active").removeClass("active");

        //Open page content
        $(".account-page .pages-container .page[data-page='" + page + "']").addClass("open");

        //Active open page button
        $(".account-page .nav-tabs-row .nav-tabs .nav-item button[data-page='" + page + "']").parent().addClass("active");
    }
});

//Invoice table pagination
body.on("click", ".account-page .page[data-page='billing'] .table-pagination .page-links button", function(e) {
    e.preventDefault();

    //Destination
    let destination = $(this).data("go-to-page");

    //No destination found
    if(destination === undefined){
        return;
    }

    //Clear existing
    $(".account-page .page[data-page='billing'] .invoices-table tbody tr").not(".loading").remove();

    //Enable loader
    $(".account-page .page[data-page='billing'] .invoices-table tbody .loading").removeClass("hide");

    //Disable all pagination buttons
    $(".account-page .page[data-page='billing'] .table-pagination .page-links button").attr("disabled", true);

    //Get next page info
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: [
            {
                name: "action",
                value: "invoicesTablePage"
            },
            {
                name: "page",
                value: destination
            }
        ],
        success: function (data) {
            //Enable all pagination buttons
            $(".account-page .page[data-page='billing'] .table-pagination .page-links button").prop("disabled", false);

            //Disable loader
            $(".account-page .page[data-page='billing'] .invoices-table tbody .loading").addClass("hide");

            //Load rows
            let invoiceCount = data.invoices.length;
            for (let i = 0; i < invoiceCount; i++) {
                let invoice = data.invoices[i];
                $(".account-page .page[data-page='billing'] .invoices-table tbody").append("<tr><td>" + invoice.reference + "</td><td><span class='paid-badge'>" + invoice.status + "</span></td><td>" + invoice.total + "</td><td>" + invoice.timestamp  + " - " + invoice.timeSince + "</td><td></td></tr>");
            }

            //Page number
            let pageNumber = parseInt(data.pageNumber);

            //Update results count
            $(".account-page .page[data-page='billing'] .table-pagination .results-count").text("Showing " + data.fromCount + "-" + (data.fromCount + invoiceCount - 1) + " of " + data.invoiceCount);

            //Get pagination elements
            let previousPreviousButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .previousPrevious");
            let previousButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .previous");
            let nextButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .next");
            let nextNextButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .nextNext");
            let backTwoButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .back-two");
            let backOneButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .back-one");
            let forwardOneButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .forward-one");
            let forwardTwoButton = $(".account-page .page[data-page='billing'] .table-pagination .page-links .forward-two");

            //Update current button number
            $(".account-page .page[data-page='billing'] .table-pagination .page-links .number-button.current").text(pageNumber);

            //Update pagination buttons
            if(data.pagination.previousPrevious){
                previousPreviousButton.prop("disabled", false);
                previousPreviousButton.data("go-to-page", pageNumber - 2);
            } else {
                previousPreviousButton.attr("disabled", true);
            }

            if(data.pagination.previous){
                previousButton.prop("disabled", false);
                previousButton.data("go-to-page", pageNumber - 1);
            } else {
                previousButton.attr("disabled", true);
            }

            if(data.pagination.next){
                nextButton.prop("disabled", false);
                nextButton.data("go-to-page", pageNumber + 1);
            } else {
                nextButton.attr("disabled", true);
            }

            if(data.pagination.nextNext){
                nextNextButton.prop("disabled", false);
                nextNextButton.data("go-to-page", pageNumber + 2);
            } else {
                nextNextButton.attr("disabled", true);
            }

            //Update pagination numbers
            if(pageNumber > 2){
                backTwoButton.removeClass("hide");
                backTwoButton.text(pageNumber - 2);
                backTwoButton.data("go-to-page", pageNumber - 2);
            } else {
                backTwoButton.addClass("hide");
            }

            if(pageNumber > 1){
                backOneButton.removeClass("hide");
                backOneButton.text(pageNumber - 1);
                backOneButton.data("go-to-page", pageNumber - 1);
            } else {
                backOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 1){
                forwardOneButton.removeClass("hide");
                forwardOneButton.text(pageNumber + 1);
                forwardOneButton.data("go-to-page", pageNumber + 1);
            } else {
                forwardOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 2){
                forwardTwoButton.removeClass("hide");
                forwardTwoButton.text(pageNumber + 2);
                forwardTwoButton.data("go-to-page", pageNumber + 2);
            } else {
                forwardTwoButton.addClass("hide");
            }
        }
    });
});

//Survey table pagination
body.on("click", ".account-page .page[data-page='surveys'] .table-pagination .page-links button", function(e) {
    e.preventDefault();

    //Destination
    let destination = $(this).data("go-to-page");

    //No destination found
    if(destination === undefined){
        return;
    }

    //Clear existing
    $(".account-page .page[data-page='surveys'] .surveys-table tbody tr").not(".loading").remove();

    //Enable loader
    $(".account-page .page[data-page='surveys'] .surveys-table tbody .loading").removeClass("hide");

    //Disable all pagination buttons
    $(".account-page .page[data-page='surveys'] .table-pagination .page-links button").attr("disabled", true);

    //Get next page info
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: [
            {
                name: "action",
                value: "surveysTablePage"
            },
            {
                name: "page",
                value: destination
            }
        ],
        success: function (data) {
            //Enable all pagination buttons
            $(".account-page .page[data-page='surveys'] .table-pagination .page-links button").prop("disabled", false);

            //Disable loader
            $(".account-page .page[data-page='surveys'] .surveys-table tbody .loading").addClass("hide");

            //Load html
            $(".account-page .page[data-page='surveys'] .surveys-list").html(data.html);

            //Page number
            let pageNumber = parseInt(data.pageNumber);

            //Update results count
            $(".account-page .page[data-page='surveys'] .table-pagination .results-count").text("Showing " + data.fromCount + "-" + (data.fromCount + data.pageResultsCount - 1) + " of " + data.surveyCount);

            //Get pagination elements
            let previousPreviousButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .previousPrevious");
            let previousButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .previous");
            let nextButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .next");
            let nextNextButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .nextNext");
            let backTwoButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .back-two");
            let backOneButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .back-one");
            let forwardOneButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .forward-one");
            let forwardTwoButton = $(".account-page .page[data-page='surveys'] .table-pagination .page-links .forward-two");

            //Update current button number
            $(".account-page .page[data-page='surveys'] .table-pagination .page-links .number-button.current").text(pageNumber);

            // Scroll to top of page
            $("html, body").animate({ scrollTop: 0 }, "slow");

            //Update pagination buttons
            if(data.pagination.previousPrevious){
                previousPreviousButton.prop("disabled", false);
                previousPreviousButton.data("go-to-page", pageNumber - 2);
            } else {
                previousPreviousButton.attr("disabled", true);
            }

            if(data.pagination.previous){
                previousButton.prop("disabled", false);
                previousButton.data("go-to-page", pageNumber - 1);
            } else {
                previousButton.attr("disabled", true);
            }

            if(data.pagination.next){
                nextButton.prop("disabled", false);
                nextButton.data("go-to-page", pageNumber + 1);
            } else {
                nextButton.attr("disabled", true);
            }

            if(data.pagination.nextNext){
                nextNextButton.prop("disabled", false);
                nextNextButton.data("go-to-page", pageNumber + 2);
            } else {
                nextNextButton.attr("disabled", true);
            }

            //Update pagination numbers
            if(pageNumber > 2){
                backTwoButton.removeClass("hide");
                backTwoButton.text(pageNumber - 2);
                backTwoButton.data("go-to-page", pageNumber - 2);
            } else {
                backTwoButton.addClass("hide");
            }

            if(pageNumber > 1){
                backOneButton.removeClass("hide");
                backOneButton.text(pageNumber - 1);
                backOneButton.data("go-to-page", pageNumber - 1);
            } else {
                backOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 1){
                forwardOneButton.removeClass("hide");
                forwardOneButton.text(pageNumber + 1);
                forwardOneButton.data("go-to-page", pageNumber + 1);
            } else {
                forwardOneButton.addClass("hide");
            }

            if((data.pageCount - pageNumber) >= 2){
                forwardTwoButton.removeClass("hide");
                forwardTwoButton.text(pageNumber + 2);
                forwardTwoButton.data("go-to-page", pageNumber + 2);
            } else {
                forwardTwoButton.addClass("hide");
            }
        }
    });
});

//Purchase credits page
body.on("click", "#purchase-credits .quantity-field .increment-buttons .add, #purchase-credits .quantity-field .increment-buttons .subtract", function(e){
    //Get quantity input
    let input = $("#purchase-credits .quantity-field .quantity-input");

    //Current quantity
    let currentQuantity = parseInt(input.val());
    if(isNaN(currentQuantity)) {
        currentQuantity = 0;
    }

    //Quick button has been pressed
    if($(this).hasClass("add")){
        input.val( currentQuantity + 1);
    } else {
        if(currentQuantity > 0){
            input.val( currentQuantity - 1);
        }
    }

    //Refresh the purchase credit form
    refreshPurchaseCreditForm();
});

body.on("input", "#purchase-credits .promo-input", function(e) {
    //Get promo code input value
    let promoCode = $(this).val();

    //Disable apply button if no code inputted
    if (promoCode === ""){
        $("#purchase-credits .apply-discount").prop("disabled", true);
    } else {
        $("#purchase-credits .apply-discount").removeAttr('disabled');
    }
});

body.on("keypress", "#purchase-credits .promo-input", function(e) {
    if (e.which === 13) {
        $("#purchase-credits .apply-discount").click();
    }
});

body.on("click", "#purchase-credits .apply-discount", function(e) {
    //Get elements
    let discountMessage = $("#purchase-credits .discount-message");
    let discountInput = $("#purchase-credits .promo-input");
    let applyButton = $(this);

    //Disable apply button until request is complete
    applyButton.prop("disabled", true);

    //Get promo code input value
    let promoCode = discountInput.val();

    //Hide any existing messages
    discountMessage.addClass("hide");
    discountMessage.removeClass("error");
    discountMessage.removeClass("success");
    discountMessage.text("");

    let formData = [
        {
            name: "action",
            value: "applydiscount"
        },
        {
            name: "code",
            value: promoCode
        }
    ];

    $.ajax({
        type: "POST",
        url: window.location.href,
        data: formData,
        success: function (data) {
            //Re-enable apply button
            applyButton.removeAttr('disabled');

            if(data.success){
                //Save discount details
                discountInput.data("type", data.type);
                discountInput.data("amount", data.amount);

                //Set success message
                discountMessage.text(data.message);
                discountMessage.removeClass("hide");
                discountMessage.addClass("success");

                //Refresh the purchase credit form
                refreshPurchaseCreditForm();
            } else {
                //Set error message
                discountMessage.text(data.message);
                discountMessage.removeClass("hide");
                discountMessage.addClass("error");

                //Clear input
                discountInput.val("");
            }
        }
    });
});

body.on("click", "#purchase-credits .remove-discount", function(e) {
    //Get elements
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountMessage = $("#purchase-credits .discount-message");

    //Remove discount from input
    discountInput.val("");

    //Delete discount info
    discountInput.data('type', '');
    discountInput.data('amount', '');

    //Hide any existing messages
    discountMessage.addClass("hide");
    discountMessage.removeClass("error");
    discountMessage.removeClass("success");
    discountMessage.text("");

    //Clear breakdown row
    $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount");

    //Refresh credit form
    refreshPurchaseCreditForm();
});

body.on("click", "#purchase-credits .checkout-button", function(e) {
    //Get elements
    let quantityInput = $("#purchase-credits .quantity-input");
    let quantityButtons = $("#purchase-credits .quantity-field .increment-buttons .add, #purchase-credits .quantity-field .increment-buttons .subtract")
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountButtons = $("#purchase-credits .discount-field .apply-discount, #purchase-credits .discount-field .remove-discount");
    let checkoutButton = $(this);

    //Get values
    let quantity = quantityInput.val();
    let discountCode = "";

    //Retrieve discount code if one is set
    if($("#purchase-credits .discount-field").hasClass("discount-applied")){
        discountCode = discountInput.val();
    }

    //Disable inputs
    quantityInput.prop("disabled", true);
    discountInput.prop("disabled", true);
    discountButtons.prop("disabled", true);
    quantityButtons.prop("disabled", true);
    checkoutButton.prop("disabled", true);

    //Hide any existing error messages
    $("#purchase-credits .error-message .message").text("");
    $("#purchase-credits .error-message").addClass("hide");

    let formData = [
        {
            name: "action",
            value: "checkout"
        },
        {
            name: "quantity",
            value: quantity
        },
        {
            name: "discountCode",
            value: discountCode
        }
    ];

    $.ajax({
        type: "POST",
        url: window.location.href,
        data: formData,
        success: function (data) {
            console.log(data);
            if(data.success){
                window.location.replace(data.paymentUrl);
            } else {
                switch(data.reason){
                    case "invalid_discount_code":
                        //Show error message
                        $("#purchase-credits .error-message .message").text(data.message);
                        $("#purchase-credits .error-message").removeClass("hide");

                        //Remove discount
                        $("#purchase-credits .discount-field").removeClass("discount-applied");
                        $("#purchase-credits .discount-field .promo-input").removeAttr('disabled').val("").data("type", "").data("amount", "");

                        //Hide any existing messages
                        $("#purchase-credits .discount-message").addClass("hide").removeClass("error").removeClass("success").text("");
                        break;
                    default:
                        //Show error message
                        $("#purchase-credits .error-message .message").text(data.message);
                        $("#purchase-credits .error-message").removeClass("hide");
                        break;
                }

                //Enable inputs
                quantityInput.removeAttr('disabled');
                discountInput.removeAttr('disabled');
                discountButtons.removeAttr('disabled');
                quantityButtons.removeAttr('disabled');
                checkoutButton.removeAttr('disabled');

                //Refresh credit form
                refreshPurchaseCreditForm();
            }
        }
    });
});

//Redeem credits code
body.on("input", "#redeem-credits-code .code-field .code-section input", function(e) {
    let dataSection = $(this).data("section");
    let inputValueLength = $(this).val().length;

    if(inputValueLength === 5 && dataSection !== 4){
        //Move cursor to next input
        $("#redeem-credits-code .code-field .code-section input[data-section=" + (dataSection+1) + "]").focus();
    }

    checkAccountRedeemCreditsCodeForm();
});


body.on("click", "#redeem-credits-code .redeem-code-button", function(e){
    let redeemCodeButton = $(this);
    let codeInputs = $("#redeem-credits-code .code-field .code-section input");
    let code = "";

    //Disable submit button
    redeemCodeButton.prop('disabled', true);

    //Hide error message
    $("#redeem-credits-code .error-message .description").text("");
    $("#redeem-credits-code .error-message").addClass("hide");

    //Hide success message
    $("#redeem-credits-code .success-block").addClass("hide");

    //Loop inputs
    codeInputs.each(function() {
        code = code + $(this).val();
    });

    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            action: "redeemCode",
            code: code
        },
        success: function (data) {
            if(data.success){
                $("#redeem-credits-code .success-block .creditAmount").text(data.creditsGiven);
                $("#redeem-credits-code .success-block").removeClass("hide");
            } else {
                //Show error message
                $("#redeem-credits-code .error-message .description").text(data.message);
                $("#redeem-credits-code .error-message").removeClass("hide");
            }

            //Clear inputs
            codeInputs.val("");
        }
    });
});

body.on("click", "#subscriptionPayCancellationFee", function(e) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            action: "subscriptionPayCancellationFee",
        },
        success: function (data) {
            if (data.success) {
                window.location.replace(data.paymentUrl);
            } else {
                console.error(data)
                alert("An error occurred. Please try again.")
            }
        }
    });
});

body.on("click", "#subscriptionPlanCancellation", function(e) {
    $.ajax({
        type: 'POST',
        url: window.location.href,
        data: {
            action: "subscriptionPlanCancellation",
        },
        success: function (data) {
            if (data.success) {
                window.location.replace(data.url);
            } else {
                alert("An error occurred. Please try again.")
            }
        }
    });
});

// Close modals
body.on("click", ".modal.subscription-agreement-modal .close-modal, .modal.subscription-cancellation-modal .close-modal, .modal.subscription-plan-cancellation-modal .close-modal", function(e){
    // Redirect to account page
    window.location.replace("/account#billing");
});


// View survey reports
body.on("click", ".account-page .surveys-list .survey-item .survey-actions .view-survey-reports", function(e) {
    // If button disabled, return
    if($(this).prop("disabled")) return;

    const surveyReportsModal = $(".modal.survey-reports-modal");
    const surveyId = $(this).data("survey-id");

    console.log(surveyId);

    // Set survey id in modal
    surveyReportsModal.find(".survey-id").text(surveyId);

    // Show loader
    surveyReportsModal.find(".loader").show();

    // Hide reports list
    surveyReportsModal.find(".reports-list").hide();

    // Retrieve the survey's reports
    getSurveyReports(surveyId).then((response) => {
        // Clear reports list
        surveyReportsModal.find(".reports-list").empty();

        response.data.reports

        // Loop through reports
        response.data.reports.forEach((report) => {
            // Create report element
            const reportElement = `
                <div class="report-item">
                    <div>
                        <i class="report-icon fa-regular fa-file-chart-column"></i>
                        <div class="report-details">
                            <span class="report-type">${report.name}</span>
                            ${report.timeSince ? `<span class="report-created">${report.timeSince}</span>` : ''}
                        </div>
                    </div>
                    <div>
                        <a href="${report.downloadLink}" class="view-survey wtp-button small-button has-icon icon-prefix" target="_blank">
                            <i class="icon fa-regular fa-download"></i>
                            Download
                        </a>
                    </div>
                </div>
            `;


            // Append report element to reports list
            surveyReportsModal.find(".reports-list").append(reportElement);

            // Hide loader
            surveyReportsModal.find(".loader").hide();

            // Show reports list
            surveyReportsModal.find(".reports-list").show();
        });


        console.log(response);
    }).catch((error) => {
        console.error(error);
    });


    // Open survey reports modal
    surveyReportsModal.addClass("open");
});

// Close survey reports modal
body.on("click", ".modal.survey-reports-modal .close-modal", function(e) {
    // Close survey reports modal
    $(".modal.survey-reports-modal").removeClass("open");
});

// On continue, submit the form
body.on("click", ".modal #billingAddressFormModalSubmit", function(e) {
    // Find form
    const form = $("#account-page-save-billing-address");

    // Submit form
    form.submit();
});

// On DOM change within form, check if form is valid
body.on("billingAddressSaved", "#account-page-save-billing-address", function(e) {
    // Close modal
    $(".modal[data-modal='billingAddressForm']").removeClass("open");

    const plan = $(".modal[data-modal='billingAddressForm'] input[name=planShortCode]").val();

    // Click the plan's button
    $(".wtp-button[data-plan-shortcode='" + plan + "']").click();
});

//Functions

function checkAccountLoginForm() {
    //Skip when no login form exists
    if($("#account-login-form").length === 0) return;

    //Variables
    let submitButtonDisable = false;

    //Input elements
    let emailInput = $("#account-login-form .input input[name=loginEmail]");
    let passwordInput = $("#account-login-form .input input[name=loginPassword]");
    let submitButton = $("#account-login-form .login-button");

    //Validate email input
    if(emailInput.val() === ""){
        submitButtonDisable = true;
    }

    //Validate password input
    if(passwordInput.val() === ""){
        submitButtonDisable = true;
    }

    //Make state changes
    if(submitButtonDisable){
        submitButton.prop('disabled', true);
    } else {
        submitButton.removeAttr('disabled');
    }
}


function startAccountEmailVerificationResendTimer() {
    //Skip when no login form exists
    if($(".email-verification-page").length === 0 || $(".email-verification-page .resend-button").hasClass("noresend")) return;

    //Resend button
    let resendButton = $(".email-verification-page .resend-button");

    //Amount of seconds remaining till the email can be resent
    let resendAvailable = resendButton.data("resend-timestamp");

    if(resendAvailable > 0){
        //Disable button
        resendButton.attr("disabled", true);

        //Change button text
        resendButton.text("Resend email (" + resendAvailable + " secs)");

        //Start update time
        let loop = setInterval(updateResendButton, 1000);
        function updateResendButton() {
            resendAvailable -= 1;
            if(resendAvailable < 1){
                resendButton.removeAttr('disabled');
                resendButton.text("Resend email");

                //Clear existing notice
                let resendNotice = $(".email-verification-page .resend-notice");
                resendNotice.text("");
                resendNotice.removeClass("error");
                resendNotice.removeClass("success");

                clearInterval(loop);
            } else {
                resendButton.text("Resend email (" + resendAvailable + " secs)");
            }
        }
    }
}

function startAccountPaymentConfirmationCheckTimer(){
    //Skip when no login form exists
    if($(".account-generic-container .waiting-confirmation").length === 0) return;

    //Start update time
    let loop = setInterval(checkStatus, 5000);
    function checkStatus() {
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: {
                action: "paymentcheck",
                stripeId: $(".account-generic-container .waiting-confirmation #stripeId").val()
            },
            success: function (data) {
                console.log(data);


                if(data.success){
                    if(data.paid === 1){
                        //Stop looping
                        clearInterval(loop);

                        //Hide confirming payment screen
                        $(".account-generic-container .waiting-confirmation").hide();

                        //Show payment confirmed screen
                        $(".account-generic-container .payment-confirmed").css("display", "flex");
                    }
                } else {

                }
            }
        });
    }
}

function startAccountEmailVerificationCheckTimer() {
    //Skip when no login form exists
    if($(".email-verification-page").length === 0) return;

    //Start update time
    let loop = setInterval(checkStatus, 5000);
    function checkStatus() {
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: {
                action: "check",
                publicToken: $(".email-verification-page #public-token").val()
            },
            success: function (data) {
                if(data.success){
                    window.location.replace(data.url);
                } else {
                    if(data.reason !== "unverified"){
                        clearInterval(loop);
                        alert("An error with your email has occurred.")
                    }
                }
            }
        });
    }
}

function checkAccountPasswordReset() {
    //Skip when no login form exists
    if($("#account-password-reset-form").length === 0) return;

    //Input elements
    let emailInput = $("#account-password-reset-form .input input[name=registeredEmail]");
    let submitButton = $("#account-password-reset-form .password-reset-button");

    //Variables
    let submitButtonDisable = false;

    //Validate email input
    if(emailInput.val() === ""){
        submitButtonDisable = true;
    }

    //Make state changes
    if(submitButtonDisable){
        submitButton.prop('disabled', true);
    } else {
        submitButton.removeAttr('disabled');
    }
}

function refreshPurchaseCreditForm(){
    //Pricing brackets
    let brackets = [
        {
            unit_price: 5.00,
            quantity: {
                start: 0,
            }
        },
        // {
        //     unit_price: 2.50,
        //     quantity: {
        //         start: 100,
        //         end: 999
        //     }
        // },
        // {
        //     unit_price: 10.00,
        //     quantity: {
        //         start: 1000
        //     }
        // }
    ];

    //Quantity
    let quantity = parseInt($("#purchase-credits .quantity-field .quantity-input").val());
    if(isNaN(quantity)) {
        quantity = 0;
    }

    //Find pricing bracket
    let bracket = brackets[0];
    let bracketNumber = 0;
    let arrayLength = brackets.length;
    for (let i = 0; i < arrayLength; i++) {
        if('end' in brackets[i].quantity){
            //Check to see if the quantity is in the pricing bracket
            if(quantity >= brackets[i].quantity.start && quantity <= brackets[i].quantity.end){
                bracket = brackets[i];
                bracketNumber = i + 1;
            }
        } else {
            //Check to see if the quantity is in the pricing bracket
            if(quantity >= brackets[i].quantity.start){
                bracket = brackets[i];
                bracketNumber = i + 1;
            }
        }
    }

    //Activate the correct pricing tier in the interface
    $("#purchase-credits .pricing-tiers .tier.active").removeClass("active");
    $("#purchase-credits .pricing-tiers .tier[data-bracket='" + bracketNumber + "']").addClass("active");


    //Calculate price figures
    let unitPrice = bracket.unit_price;
    let subtotal = quantity * unitPrice;

    //Find discount amount
    let discount = 0
    let discountInput = $("#purchase-credits .discount-field .promo-input");
    let discountType = discountInput.data('type');
    let discountAmount = discountInput.data('amount');
    if(discountType !== ""){
        $("#purchase-credits .discount-field").addClass("discount-applied");
        discountInput.prop('disabled', true);
        if(discountType === "flat"){
            discount = discountAmount;
            //Stop discount causing negative totals
            if(discount > subtotal){
                discount = subtotal;
            }
            $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount (" + discountInput.val() + " - " + discountAmount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}) + " off)");
        } else {
            discount = subtotal * (discountAmount/100);
            $("#purchase-credits #account-credit-discount").siblings(".left").text("Discount (" + discountInput.val() + " - " + discountAmount.toFixed(2) + "% off)");
        }
    } else {
        $("#purchase-credits .discount-field").removeClass("discount-applied");
        discountInput.prop('disabled', false);
    }
    let tax = (subtotal - discount) * 0.2;
    let grandTotal = (subtotal - discount) + tax;

    //Update price figures in interface
    $("#purchase-credits #account-credit-subtotal").text(subtotal.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));

    if(discount > 0){
        $("#purchase-credits #account-credit-discount").text("-" + discount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    } else {
        $("#purchase-credits #account-credit-discount").text(discount.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    }

    $("#purchase-credits #account-credit-tax").text(tax.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));
    $("#purchase-credits #account-credit-grand-total").text(grandTotal.toLocaleString("en-GB", {style:"currency", currency:"GBP"}));

    //If zero quantity or no payment method is selected
    if(quantity > 0){
        $("#purchase-credits .checkout-button").prop('disabled', false);
    } else {
        $("#purchase-credits .checkout-button").prop('disabled', true);
    }
}

function checkAccountRedeemCreditsCodeForm() {
    //Skip when no login form exists
    if($("#redeem-credits-code").length === 0) return;

    //Input elements
    let codeInputs = $("#redeem-credits-code .code-field .code-section input");
    let redeemCodeButton = $("#redeem-credits-code .redeem-code-button");

    //Variables
    let submitButtonDisable = false;

    //Loop inputs
    codeInputs.each(function() {
        if($(this).val().length < 5){
            submitButtonDisable = true;
        }
    });

    //Make state changes
    if(submitButtonDisable){
        redeemCodeButton.prop('disabled', true);
    } else {
        redeemCodeButton.removeAttr('disabled');
    }
}

