import {apiRequest} from "./index";

/**
 * Get the survey's reports
 * @param surveyId
 * @returns {Promise<unknown>}
 */
export function getSurveyReports(surveyId){
    return new Promise(function(resolve, reject){
        apiRequest('survey', 'reports', {
            surveyId: surveyId,
        }).then(function(response) {
            resolve(response);
        }).catch(function(error) {
            alert(error?.message || error?.responseText);
            reject(error);
        })
    });
}
