/*
    * Load the property's current form step's html
    * @param {number} propertyId
 */
import {apiRequest} from "./index";

export function saveBillingAddress(formData){
    return new Promise(function(resolve, reject){
        apiRequest('account', 'saveBillingAddress', formData).then(function(response) {
            if (response.success) {
                resolve(response);
            } else {
                reject(response);
            }
        }).catch(function(error) {
            reject(error);
        })
    });
}